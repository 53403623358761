<template>
  <div class="print-invoice-btn btn-styl"
       :id="'id-' + _uid"
       @click="open = true"
  >
    <div class="print-invoice-btn__txt"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit"
           @click="editTranslate([
                'mypayments_printInvoice',
              ])"></div>
      {{datePickerTxt ? datePickerTxt : $t('mypayments_printInvoice.localization_value.value')}}

      <div class="link-btn-date"
           v-if="datePicker === true"
      >
        <DatePickerDefault
            class="link-btn-date__inner"
        >
          <template slot="body">
            <date-picker
                class=""
                v-model="date"
                valueType="format"
                :range="datePickerRange != undefined && datePickerRange === false ? false : true"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="datePickerFormat"
                :type="datePickerType || 'date'"
                :disabled-date="(dateI) => getDisabledDays(dateI)"
                range-separator=" - "
                @change="selectDate"
                :open="open"
                :popup-class="'mobile-center'"
            >
              <template slot="footer">
                <div class="date-picker__footer d-flex align-items-center">
                  <span class="site-link mr-3"
                        @click="cancelFunc">
                    {{ $t('common_cancel.localization_value.value') }}
                  </span>
                  <MainButton
                      :value="$t('invoices_print.localization_value.value')"
                      @click.native="datePickerBtn"
                  />
                </div>
              </template>
            </date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import MainButton from "../../UI/buttons/MainButton/MainButton";
  import DatePickerDefault from "../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import {datePickerMixin} from "../../../mixins/datePickerMixin/datePickerMixin";

  export default {
    name: "PrintInvoice",
    components:{
      MainButton,
      DatePickerDefault,
      DatePicker,
    },

    mixins: [datePickerMixin],

    props: [
      'datePicker',
      'value',
      'datePickerTxt',
      'datePickerRange',
      'datePickerFormat',
      'datePickerType',
      'disabledDates',
    ],

    data(){
      return {
        date: this.value && this.value.length > 0 ? [this.$moment(this.value[0]).format('DD MMM YY'), this.$moment(this.value[1]).format('DD MMM YY')] : '',
        open: false,
      }
    },

    watch: {
      value(newVal) {
        this.date = this.value && this.value.length > 0 ? [this.$moment(newVal[0]).format('DD MMM YY'), this.$moment(newVal[1]).format('DD MMM YY')] : ''
      }
    },

    mounted() {
      this.closeHiddenDatePicker(this, this._uid)
    },

    beforeDestroy() {
      this.removeEventCloseDatePicker(this)
    },

    methods: {

      getDisabledDays(date) {
        // console.log(date);
        if(this.disabledDates === 'currentMonth'){
          let newDate = new Date();
          return date + '' == '' + new Date(newDate.getFullYear(), newDate.getMonth(), 1)
        }
        if(this.disabledDates === 'currentAndNextMonth'){
          let newDate = new Date();
          return this.$moment(date) > this.$moment(newDate).subtract(1, 'months')
        }
        return false
      },

      selectDate(date) {
        console.log(date);
        this.$emit('changeDate', date)
      },

      datePickerBtn(){
        this.$emit('datePickerBtn')

        this.open = false
      },

      cancelFunc() {
        this.open = false
        this.date = ''
      },

      close(){
        this.open = false
      },

    }
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .mx-calendar-panel-month {
    .mx-btn-icon-double-left, .mx-btn-icon-double-right {
      display: initial !important;

      .mx-icon-double-left, .mx-icon-double-right {
        &:before {
          height: 15px !important;
          width: 15px !important;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          border-left: 2px solid #8F7A61 !important;
          border-top: 2px solid #8F7A61 !important;
        }

        &::after {
          display: none;
        }
      }

    }
  }

  .print-invoice-btn{

    .link-btn-date{
      transform: translateY(-25px);
    }

    &__txt{
      position: relative;
      padding-left: 22px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $accent;
      cursor: pointer;

      @include for-1120 {
        font-size: 14px;
      }

      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        display: block;
        width: 14px;
        height: 14px;
        background: url("../../../assets/img/common/print-accent-icon.svg") center/contain no-repeat;
      }

    }
  }
</style>
