<template>
  <div class="fragment">
    <template v-if="$store.getters.getIE559Report.length > 0 && !$store.getters.getIE559ReportLoading">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_id',
          'common_user',
          'common_date',
          'IE559_Name',
          'IE559_letterDocument',
          'IE559_formDocument',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('common_id.localization_value.value')}}</th>
            <th v-if="isAdmin">{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('common_date.localization_value.value')}}</th>
            <th>{{$t('IE559_Name.localization_value.value')}}</th>
            <th style="width: 100%"></th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getIE559Report"
              :key="index"
          >
            <td>
              {{item.id}}
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.created_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.name"
              />
            </td>
            <td style="width: 100%;">
            </td>

            <td>
              <div class="table-row">
                <LinkButton
                    :value="$t('IE559_letterDocument.localization_value.value')"
                    :type="'docs'"
                    @click.native="getLetterDocument(item)"
                />
                <LinkButton
                    class="ml-2"
                    :value="$t('IE559_formDocument.localization_value.value')"
                    :type="'docs'"
                    @click.native="getFormDocument(item)"
                />
                <LinkButton
                    v-if="isAdmin"
                    class="ml-2"
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="deleteItem(item)"
                />
<!--                <div class="table-right table-manage-list table-manage-list&#45;&#45;small">-->
<!--                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"-->
<!--                                 class="right"-->
<!--                  >-->
<!--                    <template v-if="_.has(item.letter_document_file, 'id')" slot="item">-->
<!--                     -->
<!--                    </template>-->
<!--                    <template v-if="_.has(item.form_document_file, 'id')" slot="item">-->
<!--                      -->
<!--                    </template>-->
<!--                  </ManagerButton>-->
<!--                </div>-->
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getIE559Report"
                 :key="index"
            >
              <IE559ReportTableMobile
                  :item="item"
                  @getLetterDocument="getLetterDocument(item)"
                  @getFormDocument="getFormDocument(item)"
                  @deleteItem="deleteItem(item)"
              />
            </div>

          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getIE559ReportCommonList.next_page_url !== null && $store.getters.getIE559Report.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextIE559ReportPage}"
                :count="$store.getters.getIE559ReportCommonList.total - $store.getters.getIE559ReportForPage * $store.getters.getIE559ReportCommonList.current_page < $store.getters.getIE559ReportForPage ?
                  $store.getters.getIE559ReportCommonList.total - $store.getters.getIE559ReportForPage * $store.getters.getIE559ReportCommonList.current_page:
                  $store.getters.getIE559ReportForPage"
            />
<!--            <ExportBtn-->
<!--                @click.native="$emit('downloadFiles')"-->
<!--                class="table-bottom-btn__right"/>-->
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getIE559ReportLoading === false && $store.getters.getIE559Report.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import IE559ReportTableMobile from "./IE559ReportTableMobile/IE559ReportTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  // import ManagerButton from "@/components/UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";


  export default {
    name: "IE559ReportTable",

    components: {
      LinkButton,
      // ManagerButton,
      ValueHelper,
      TableUserColumn,
      NoResult,
      IE559ReportTableMobile,
      ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },


    data() {
      return {

      }
    },

    methods: {

      deleteItem(item) {
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('deleteIE559Report', item.id).then(response => {
            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.openNotify('success', 'common_notificationRecordDeleted')
              this.$emit('reload')
            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      getLetterDocument(item){
        console.log(item.letter_document_file);
        this.$store.dispatch('getFileFromServer', item.letter_document_file.id).then((response) => {
          if(response[item.letter_document_file.id]) {
            this.globalDownloadBase64File(
                `application/${item.letter_document_file.extension}`,
                response[item.letter_document_file.id],
                item.letter_document_file.original_filename
            )
          }
        })
      },

      getFormDocument(item){
        console.log(item.form_document_file);
        this.$store.dispatch('getFileFromServer', item.form_document_file.id).then((response) => {
          if(response[item.form_document_file.id]) {
            this.globalDownloadBase64File(
                `application/${item.form_document_file.extension}`,
                response[item.form_document_file.id],
                item.form_document_file.original_filename
            )
          }
        })
      },

    },

  }

</script>

<style scoped lang="scss">
  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 356px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .table-manage-list {
    width: 240px;


    .manager-btn {
      max-width: 370px;
      width: 100%;
    }
  }



  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .conso-user-select {
    min-width: 150px;
  }

  .site-table .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

</style>
